import react, { KeyboardEvent, useRef } from "react";
import Layout from "./layout";
import styled from "styled-components";
import { apiFetch } from "../utils/fetch";
import useAuthStore from "../stores/authStore";
import { fontSize } from "../styles/Font";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const Wrapper = styled.div`
  display: flex;
  max-width: 1040px;
  background: #fff;
  border-radius: 20px;
  overflow: hidden;
`;
const ImageWrapper = styled.div``;
const Image = styled.img`
  width: 100%;
  height: 100%;
`;
const LoginWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 17% 0;
`;
const HeaderLogo = styled.div`
  width: 50%;
`;
const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const Header = styled.div`
  ${fontSize(20, "L", "black")}
  padding: 5%;
`;
const InputWrapper = styled.div`
  display: flex;
  gap: 1%;
  align-items: center;
  justify-content: space-between;
  padding: 0 15% 0 5%;
  margin: 2.5% 0;
`;
const Label = styled.div`
  flex: 1.5;
  text-align: right;
`;
const Input = styled.input`
  border: 1px solid #777777;
  padding: 1% 3%;
  flex: 6;
  font-size: 20px;
`;
const Button = styled.div`
  ${fontSize(16, "M", "white")}
  background: rgb(19, 127, 185);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 3% 3%;
  flex: 6;
}
`;
const LoginPage = () => {
  const idRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const { setLoginState } = useAuthStore();
  const handleOnKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      loginFn();
    }
  };
  const loginFn = () => {
    if (idRef.current?.value && passwordRef.current?.value) {
      apiFetch("/api/v2.0/auth", "POST", {
        username: idRef.current?.value,
        password: passwordRef.current?.value,
      }).then((data) => {
        if (data.login === true) {
          setLoginState(data.login);
          localStorage.setItem("loginState", data.login);
          window.location.href = "/";
        } else {
          alert(data.message);
        }
      });
    }
  };
  return (
    <section style={{ background: "rgba(247, 248, 250, 1)" }}>
      <Layout>
        <PageWrapper>
          <Wrapper>
            <ImageWrapper>
              <Image src="https://d17mmzdc0h66bb.cloudfront.net/image/login_banner.jpg" />
            </ImageWrapper>
            <LoginWrapper>
              <HeaderWrapper>
                <HeaderLogo>
                  <Image src="https://d17mmzdc0h66bb.cloudfront.net/image/logo.png" />
                </HeaderLogo>
                <Header>세왕상사 홈페이지 관리자페이지</Header>
              </HeaderWrapper>
              <LoginContainer>
                <InputWrapper>
                  <Label>아이디</Label>
                  <Input type="text" ref={idRef} />
                </InputWrapper>
                <InputWrapper>
                  <Label>비밀번호</Label>
                  <Input
                    onKeyDown={handleOnKeyPress}
                    type="password"
                    ref={passwordRef}
                  />
                </InputWrapper>
                <InputWrapper style={{ margin: "5% 0" }}>
                  <Label />
                  <Button onClick={loginFn}>로그인</Button>
                </InputWrapper>
              </LoginContainer>
            </LoginWrapper>
          </Wrapper>
        </PageWrapper>
      </Layout>
    </section>
  );
};

export default LoginPage;
