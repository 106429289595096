import React, { useRef } from "react";
import styled from "styled-components";
import Layout from "../../containers/layout";
import { fontPSize, fontSize } from "../../styles/Font";
import { Slide, SlideshowRef } from "react-slideshow-image";
import { imageParser } from "../../utils/string";
import useHomePageStore from "../../stores/boardStore";
import { useNavigate } from "react-router";
import useBoardStore from "../../stores/boardStore";

const NoticeWrapper = styled.div`
  background-color: #f4f6fa;
  position: relative;
  padding: 20px 0;
`;
const NoticeContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const NoticeTop = styled.div`
  position: relative;
  margin: 10% 0;
`;
const NoticeBottom = styled.div`
  display: flex;
  align-items: end;
  margin: 10% 0;
`;
const NoticeHeaderWrapper = styled.div`
  display: flex;
  padding-bottom: 5%;
  justify-content: space-between;
`;
const NoticeHeaderTitleWrapper = styled.div``;
const NoticeHeaderTitle = styled.div`
  ${fontSize(20, "B", "black")}
  margin-bottom: 10px;
`;
const NoticeHeaderDesc = styled.div`
  ${fontPSize(14, "R", "black")}
`;
const CardWrapper = styled.div`
  border-radius: 10px;
  border: 1px solid rgba(119, 119, 119, 0.2);
  background: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
`;
const CardImageWrapper = styled.div`
  border-bottom: 1px solid rgba(119, 119, 119, 0.2);
  position: relative;
  width: 100%;
  padding-bottom: 60%;
  overflow: hidden;
`;
const CardImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
`;
const CardTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 40px;
  justify-content: center;
  flex: 1 1 0%;
  bottom: 0px;
  padding: 20px;
`;
const CardTitle = styled.div`
  ${fontSize(14, "L", "black")}
  margin-bottom: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
const CardDescWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  ${fontSize(12, "L", "#777")}
`;
const CardDate = styled.div``;
const CardLink = styled.div``;
const ArrowWrapper = styled.div`
  display: flex;
  align-items: end;
  justify-content: flex-end;
`;

const Arrow = styled.img`
  margin: 5px;
  cursor: pointer;
  width: 8.5vw;
  min-width: 30px;
  background: #fff;
  border-radius: 100px;
`;

const LinkWrapper = styled.div`
  border-radius: 10px;
  border: 1px solid rgba(119, 119, 119, 0.2);
  background: rgb(255, 255, 255);
  position: relative;
  overflow: hidden;
  width: 100%;
`;

const LinkTop = styled.div`
  position: relative;
  background: radial-gradient(
    55.93% 55.93% at 50% 60.57%,
    rgb(204, 239, 245) 20.31%,
    rgb(255, 255, 255) 100%
  );
  padding-bottom: 42%;
`;
const TopText = styled.div`
  ${fontSize(25, "B", "black")}
  position: absolute;
  top: 33%;
  left: 13%;
`;
const TopImage = styled.img`
  position: absolute;
  bottom: 0px;
  width: 100%;
`;
const LinkBottom = styled.div`
  position: relative;
  padding-bottom: 21%;
`;
const LinkLeft = styled.div`
  background: #137fb9;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50%;
  height: 100%;
`;
const LinkRight = styled.div`
  flex: 1;
  background: #3ca237;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 100%;
`;
const LinkText = styled.div`
  word-break: keep-all;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  & > pre {
    ${fontSize(16, "L", "white")}
  }
  & > img {
    width: 13%;
    min-width: 24px;
  }
`;
const HomeNotice = () => {
  const slideRef = useRef<SlideshowRef>(null);
  const navigator = useNavigate();
  const { boards } = useBoardStore();
  return (
    <section>
      <NoticeWrapper>
        <Layout>
          <NoticeContainer>
            <NoticeTop>
              <NoticeHeaderWrapper>
                <NoticeHeaderTitleWrapper>
                  <NoticeHeaderTitle>세왕소식</NoticeHeaderTitle>
                  <NoticeHeaderDesc>
                    세왕상사의 최근 소식을 알려드립니다.
                  </NoticeHeaderDesc>
                </NoticeHeaderTitleWrapper>
                <ArrowWrapper>
                  <Arrow
                    src="https://d17mmzdc0h66bb.cloudfront.net/image/slideBack.svg"
                    onClick={() => slideRef.current?.goBack()}
                  />
                  <Arrow
                    src="https://d17mmzdc0h66bb.cloudfront.net/image/slideNext.svg"
                    onClick={() => slideRef.current?.goNext()}
                  />
                </ArrowWrapper>
              </NoticeHeaderWrapper>
              {boards.length > 0 && (
                <Slide
                  slidesToShow={1}
                  ref={slideRef}
                  arrows={false}
                  indicators={false}
                  transitionDuration={200}
                >
                  {boards.map((d) => (
                    <CardWrapper
                      key={d.id}
                      onClick={() => {
                        navigator(`/news?id=${d.id}`);
                      }}
                    >
                      <CardImageWrapper>
                        <CardImage src={imageParser(d.desc)} />
                      </CardImageWrapper>
                      <CardTextWrapper>
                        <CardTitle>{d.title}</CardTitle>
                        <CardDescWrapper>
                          <CardDate>{d.date}</CardDate>
                          <CardLink
                            onClick={() => {
                              if (d.link_url) {
                                window.open(d.link_url);
                              }
                            }}
                          >
                            {d.link_url && (
                              <img src="https://d17mmzdc0h66bb.cloudfront.net/image/nLink.png" />
                            )}
                          </CardLink>
                        </CardDescWrapper>
                      </CardTextWrapper>
                    </CardWrapper>
                  ))}
                </Slide>
              )}
            </NoticeTop>
            <NoticeBottom>
              <LinkWrapper>
                <LinkTop>
                  <TopText>
                    <div style={{ display: "inline", color: "#137FB9" }}>
                      세왕
                    </div>{" "}
                    소식통
                  </TopText>
                  <TopImage src="https://d17mmzdc0h66bb.cloudfront.net/image/noticeLink1.png" />
                </LinkTop>
                <LinkBottom>
                  <LinkLeft
                    onClick={() => {
                      window.open(
                        "https://d17mmzdc0h66bb.cloudfront.net/upload/%E1%84%92%E1%85%AC%E1%84%89%E1%85%A1%E1%84%89%E1%85%A9%E1%84%80%E1%85%A2(202308).pdf"
                      );
                      // downloadRef.current?.click();
                    }}
                  >
                    <LinkText>
                      <img src="https://d17mmzdc0h66bb.cloudfront.net/image/noticeLink2.png" />
                      <pre style={{ marginLeft: "10px" }}>
                        {"세왕상사\n회사소개서"}
                      </pre>
                    </LinkText>
                  </LinkLeft>
                  <LinkRight
                    onClick={() =>
                      window.open("https://blog.naver.com/sewang88")
                    }
                  >
                    <LinkText>
                      <img src="https://d17mmzdc0h66bb.cloudfront.net/image/noticeLink3.png" />
                      <pre style={{ marginLeft: "10px" }}>
                        {"세왕상사\n공식블로그"}
                      </pre>
                    </LinkText>
                  </LinkRight>
                </LinkBottom>
              </LinkWrapper>
            </NoticeBottom>
          </NoticeContainer>
        </Layout>
      </NoticeWrapper>
    </section>
  );
};

export default HomeNotice;
